import * as React from "react"
import Layout from "../../components/layout"
import { Seo } from "../../components/seo"
import { graphql, Link } from "gatsby"
import { Breadcrumb, Space, Typography } from "antd"
import { RichText } from "prismic-reactjs"
import { linkResolver } from "../../utils/link-resolver"
import PageContent from "../../components/page-content"
const { Title } = Typography

const NewsPage = ({ data, pageContext }) => {
  if (!data) return null
  const article = data.prismicNewsArticle.data

  return (
    <Layout>
      <Seo title={article.headline} />
      <PageContent>
        <Breadcrumb>
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/press/">Press</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{article.headline}</Breadcrumb.Item>
        </Breadcrumb>
        <Space direction="vertical">
          {/* {item.headline} */}
          <Title level={2}>
            <a href={article.url.url} target={article.url.target}>
              {article.headline}
            </a>
          </Title>
          <RichText
            render={article.description.richText}
            linkResolver={linkResolver}
          />
          <Typography.Text type="secondary">
            {article.publication} - {article.publication_date}
          </Typography.Text>
        </Space>
      </PageContent>
    </Layout>
  )
}

export const query = graphql`
  query ArticleDetailQuery($id: String!) {
    prismicNewsArticle(id: { eq: $id }) {
      data {
        headline
        publication
        publication_date(formatString: "MMMM Do YYYY", locale: "en-us")
        url {
          url
        }
        description {
          richText
        }
        media_image {
          thumbnails {
            Thumbnail {
              gatsbyImageData
            }
          }
        }
      }
      id
    }
  }
`

export default NewsPage
